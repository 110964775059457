import React from "react";
import Receipt from "./Receipt"; // Make sure this path is correct

function App() {
  // Define initial values for subtotal, deliveryFee, discount, and total
  // You might want to calculate these based on the items in a real app
  const initialSubtotal = 0;
  const initialDeliveryFee = 0;
  const initialDiscount = 0;
  const initialTotal = 0;
  return (
    <div className="App">
      <main>
        <Receipt
          subtotal={initialSubtotal}
          deliveryFee={initialDeliveryFee}
          discount={initialDiscount}
          total={initialTotal}
        />
      </main>
    </div>
  );
}

export default App;
