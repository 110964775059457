// Import React

import React, { useState, useEffect } from 'react';
import './Receipt.css';



const Receipt = () => {
  const [customerName, setCustomerName] = useState("Tiwari");
  const [contact, setContact] = useState("8178069526");
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({
    description: "",
    price: "",
    quantity: 1,
  });
  const [subtotal, setSubtotal] = useState(0);

  const handleNameChange = (e) => setCustomerName(e.target.value);
  const handleContactChange = (e) => setContact(e.target.value);

  const handleNewItemChange = (e) => {
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };

  const addItem = () => {
    if (newItem.description && newItem.price) {
      setItems([
        ...items,
        {
          ...newItem,
          price: parseFloat(newItem.price),
          quantity: parseInt(newItem.quantity, 10),
        },
      ]);
      setNewItem({ description: "", price: "", quantity: 1 });
    }
  };

  useEffect(() => {
    const total = items.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setSubtotal(total);
  }, [items]);

  let date = new Date();
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  let year = date.getFullYear();

  let formattedDate = `${day}-${month}-${year}`;

  return (
    <div>
      <div>
        <div class="noprint">
          <label>
            Address:&nbsp;
            <input
              type="text"
              value={customerName}
              onChange={handleNameChange}
              style={{ display: "inline" }}
            />
          </label>
          <label>
            Address:&nbsp;
            <input
              type="text"
              value={contact}
              onChange={handleContactChange}
              style={{ display: "inline" }}
            />
          </label>
        </div>
        <div class="noprint">
          <input
            type="text"
            name="description"
            placeholder="Item description"
            value={newItem.description}
            onChange={handleNewItemChange}
          />
          <input
            type="number"
            name="price"
            placeholder="Price"
            value={newItem.price}
            onChange={handleNewItemChange}
          />
          <input
            type="number"
            step=".01"
            name="quantity"
            placeholder="Quantity"
            value={newItem.quantity}
            onChange={handleNewItemChange}
          />
          <button onClick={addItem}>Add Item</button>
        </div>

        <div>
          <h1 style={{ textAlign: "center", marginBottom: 0, marginTop: 0 }}>
            FreshBuyzar.com
          </h1>
          <h1 style={{ textAlign: "center", marginBottom: 0, marginTop: 0 }}>
            1245
          </h1>
          <hr />
          <h2 style={{ textAlign: "center", marginTop: 0 }}>{formattedDate}</h2>
          <hr />

          <div>
            <h2
              style={{
                marginBottom: 0,
                marginTop: 0,
                textAlign: "left",
                color: "black",
              }}
            >
              Address:&nbsp;&nbsp;
              <span style={{ color: "black", display: "inline" }}>
                {customerName}
              </span>
            </h2>
          </div>

          <div>
            <h2
              style={{
                marginBottom: 0,
                marginTop: 0,
                textAlign: "left",
                color: "black",
              }}
            >
              Contact:&nbsp;&nbsp;
              <span
                style={{
                  color: "black",
                  fontWeight: "medium",
                  display: "inline",
                }}
              >
                {contact}
              </span>
            </h2>
          </div>

          <hr />

          <div style={{ paddingLeft: 5 }}>
            <table width="100%" cellPadding="10">
              <tbody>
                <th>No.</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
                {/* Dynamic rendering of items */}
                {items.map((item, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      {index + 1}
                    </td>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      {item.description}
                    </td>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      {item.quantity}
                    </td>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      ₹{item.price}
                    </td>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      ₹{item.price * item.quantity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <hr />

          {/* Subtotal, Delivery fee, Discount, and Total */}
          <div style={{ marginTop: -10 }} className="flex-container">
            <h3 style={{ paddingLeft: 5, color: "black" }}>
              Subtotal: ₹{subtotal}
            </h3>
          </div>
          {/* Similar divs for deliveryFee, discount, and total */}
        </div>
      </div>
    </div>
  );
};

export default Receipt;
